
const Header = (props) => {
    const { showNav } = props
    return (
        <header>
            <a href="/" className="logo">Scott Finkelstein</a>
            { showNav === "true" &&
                <nav>
                <a href="/#home" className='active'>Home</a>
                <a href="/#apps">Apps</a>
                <a href="/#about">About</a>
                {/* <a href="/#contact">Contact</a> */}
                {/* <a href="#portfolio">Portfolio</a>
                <a href="#skills">Skills</a>
                <a href="#contact">Contact</a> */}
                </nav>
            
            }
            
        </header>
    )
}

export default Header;
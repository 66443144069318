import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/home';
import About from './components/about';
import Apps from './components/apps';
import Header from './components/header';
import SerenescapePage from './pages/serenescape';
import PrivacyPolicy from './pages/privacy';
import './assets/hiragino.ttc'

function App() {
  const updateActiveNav = () => {
    let sections = document.querySelectorAll('section')
    let navLinks = document.querySelectorAll('header nav a')

    window.onscroll = () => {
      sections.forEach(section => {
        let top = window.scrollY
        let offset = section.offsetTop - 150
        let height = section.offsetHeight
        let id = section.getAttribute('id')

        if (top >= offset && top < offset + height) {
          navLinks.forEach(link => {
            link.classList.remove('active')
            document.querySelector('header nav a[href*=' + id + ']').classList.add('active')
          })
        }
      })
    }
  }

  // const observeScrollForAnimate = () => {
    
  //   const observer = new IntersectionObserver(entries => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add('slide-up-animate')
  //       }
  //     })
  //   })

  //   const aboutObserver = new IntersectionObserver(entries => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add('fade-up-animate');
  //       }
  //     })
  //   })

  //   const portfolioItemsObserver = new IntersectionObserver(entries => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         entry.target.classList.add('slide-up-portfolio-boxes')
  //       }
  //     })
  //   })

  //   observer.observe(document.querySelector('.hero'))
  //   aboutObserver.observe(document.querySelector('.about-container'))
  //   document.querySelectorAll('.portfolio-item').forEach(item => {
  //     portfolioItemsObserver.observe(item)
  //   })
  // }

  useEffect(() => {
    updateActiveNav()
    // observeScrollForAnimate()
  }, [])
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={ <Main /> } />
          <Route path="/serenescape" element={ <SerenescapePage /> } />
          <Route path="/privacy" element={ <PrivacyPolicy /> } />
        </Routes>
      </Router>
    </>
  );
}

const Main = () => {
  return (
    <>
      <Header showNav="true" />
      <Home />
      <Apps />
      <About />
    </>
  )
}

export default App;
